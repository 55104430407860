import { EntrantState, Match, MatchState, Role } from "@xwmtp/bingo-tournament";

export const matchesResponse: Match[] = [
  {
    id: "1d471aac-8707-49b3-84c5-58fb49a6ae82",
    entrants: [
      {
        user: {
          id: "DMLq1oZ98e3OeQG8",
          name: "Eggmeister",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/egg2_2.png",
          twitchChannel: "https://www.twitch.tv/eggmeister321",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H13M6.67341S",
        finishTimeSeconds: 4386,
        racetimePlace: 1,
      },
      {
        user: {
          id: "5rNGD3DKVaB9blOy",
          name: "FantaTanked",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/jimmyW.png",
          twitchChannel: "https://www.twitch.tv/fantatanked",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H11M43.394858S",
        finishTimeSeconds: 4303,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-26T18:03:50.687Z"),
    racetimeId: "oot/charming-gohma-4237",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
    vodUrl: "https://youtu.be/WeqMCyOxp3A",
  },
  {
    id: "5ff0581d-4b47-4c3f-8554-ced8c6472863",
    entrants: [
      {
        user: {
          id: "NqO2YoLDL8o9QEya",
          name: "Jake Wright",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/flat750x075f-pad750x1000f8f8f8.png",
          twitchChannel: "https://www.twitch.tv/jakewrlght",
        },
        state: EntrantState.DidNotFinish,
      },
      {
        user: {
          id: "wNZ1KRBOV8W4qAyj",
          name: "tob3000",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/tob3000",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H13M32.622984S",
        finishTimeSeconds: 4412,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-27T18:04:31.131Z"),
    racetimeId: "oot/cunning-knuckle-6955",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/el7xHLJNbA8",
  },
  {
    id: "40dc00f0-e464-4f4c-b9f3-5eddaf53b97c",
    entrants: [
      {
        user: {
          id: "OR6ym83mvqoPd1Xr",
          name: "LadyLambdadeltasDandruff",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/images_m4K3UXM.jpg",
          twitchChannel: "https://www.twitch.tv/clairelynnd",
        },
        state: EntrantState.DidNotFinish,
      },
      {
        user: {
          id: "jQbq4dBp7yWvlrG0",
          name: "Link11",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/Link-1.png",
          twitchChannel: "https://www.twitch.tv/link__11",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H15M35.96429S",
        finishTimeSeconds: 4535,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-21T22:03:31.487Z"),
    racetimeId: "oot/prudent-kingzora-9204",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
    vodUrl: "https://youtu.be/LMe153I7RcI",
  },
  {
    id: "cabe96fe-5df0-44cf-8494-84531d3fe0d8",
    entrants: [
      {
        user: {
          id: "VXY0eABd6boLKPnz",
          name: "shaggy",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/shaggy3311",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H56M30.952824S",
        finishTimeSeconds: 6990,
        racetimePlace: 2,
      },
      {
        user: {
          id: "Va0eMongz6Wl9pyJ",
          name: "2DollarGargoyle",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/poe_triforce_better.png",
          twitchChannel: "https://www.twitch.tv/2dollargargoyle",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H13M7.516194S",
        finishTimeSeconds: 4387,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-19T22:04:02.329Z"),
    racetimeId: "oot/magnificent-gauntlet-7647",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "aGklxjWzQvoLPdye",
      name: "noface099",
      roles: [Role.Admin, Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/face_saulte_56.png",
      twitchChannel: "https://www.twitch.tv/noface099",
    },
    vodUrl: "https://youtu.be/ljNC8TeZe0k",
  },
  {
    id: "4b3a8a94-2d50-496e-b5e2-62db14af6770",
    entrants: [
      {
        user: {
          id: "dm1LPWj2DOWEnVx6",
          name: "Darker",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/UjEwSk6G_400x400.jpg",
          twitchChannel: "https://www.twitch.tv/darkerandroid",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H35M27.679748S",
        finishTimeSeconds: 5727,
        racetimePlace: 1,
      },
      {
        user: {
          id: "5rNGD3DKVaB9blOy",
          name: "FantaTanked",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/jimmyW.png",
          twitchChannel: "https://www.twitch.tv/fantatanked",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H13M29.925774S",
        finishTimeSeconds: 4409,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-19T18:08:07.172Z"),
    racetimeId: "oot/vanilla-gibdo-8848",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
  },
  {
    id: "fd041f6c-195e-4845-a1da-aa9f56f6c36b",
    entrants: [
      {
        user: {
          id: "wdm1LPWjAoEnVx6k",
          name: "dotzo",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/dotzo_-_black_aO9qZEm.png",
          twitchChannel: "https://www.twitch.tv/dotzo",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H27M53.805369S",
        finishTimeSeconds: 5273,
        racetimePlace: 2,
      },
      {
        user: {
          id: "yMewn83V613405Jv",
          name: "TKC",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/qYgfSWJ__400x400.jpg",
          twitchChannel: "https://www.twitch.tv/tkc014",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H14M2.937075S",
        finishTimeSeconds: 4442,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-15T01:00:42.514Z"),
    racetimeId: "oot/graceful-bolero-8105",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "aGklxjWzQvoLPdye",
      name: "noface099",
      roles: [Role.Admin, Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/face_saulte_56.png",
      twitchChannel: "https://www.twitch.tv/noface099",
    },
    vodUrl: "https://youtu.be/8Hc9zMfICn0",
  },
  {
    id: "a79c60c0-532e-4dbc-9ed3-3729136e90fc",
    entrants: [
      {
        user: {
          id: "JXzVwZWqElW5k8eb",
          name: "the__consultant",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/Excel_Face_257.jpg",
          twitchChannel: "https://www.twitch.tv/the__consultant",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H18M42S",
        finishTimeSeconds: 4722,
        racetimePlace: 2,
      },
      {
        user: {
          id: "7lYZa5B5eZB2Vwv9",
          name: "MutantAura",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/5454518169c837a73da4f74202a5960a.webp",
          twitchChannel: "https://www.twitch.tv/mutantaura",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H6M17.810929S",
        finishTimeSeconds: 3977,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-17T21:08:31.697Z"),
    racetimeId: "oot/fearless-bombbag-3363",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/BxcBaY2CkHg",
  },
  {
    id: "839d2f01-5a00-44bc-b74e-c445b8250033",
    entrants: [
      {
        user: {
          id: "Qbq4dBpJnrovlrG0",
          name: "skepticole",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/skepticole",
        },
        state: EntrantState.DidNotFinish,
      },
      {
        user: {
          id: "rZyM4orRvRoqDJX0",
          name: "jenslang",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/e0aaf3c8688abd0e58bed9d9f63de4ad.png",
          twitchChannel: "https://www.twitch.tv/jenslang",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H17M47.813114S",
        finishTimeSeconds: 4667,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-14T23:27:15.925Z"),
    racetimeId: "oot/reliable-bow-1555",
  },
  {
    id: "f7f96b04-9142-4a51-9c47-d3a852c328fd",
    entrants: [
      {
        user: {
          id: "OR6ym83mnjoPd1Xr",
          name: "Amateseru",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/amateseru",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H12M7.99743S",
        finishTimeSeconds: 4327,
        racetimePlace: 2,
      },
      {
        user: {
          id: "kzM65aWXgxo1y8q0",
          name: "Runnerguy2489",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/Runnerguy.png",
          twitchChannel: "https://www.twitch.tv/runnerguy2489",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H11M21.12199S",
        finishTimeSeconds: 4281,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-14T21:33:57.476Z"),
    racetimeId: "oot/clean-mushroom-5629",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
  },
  {
    id: "6c852d59-5f2d-4eef-a718-e629479f8cf3",
    entrants: [
      {
        user: {
          id: "vrZyM4orqE3qDJX0",
          name: "Woli",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/5e9d035f8ad1c385bf6db77cb5761628.jpg",
          twitchChannel: "https://www.twitch.tv/wolisecondary",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H17M19.627762S",
        finishTimeSeconds: 4639,
        racetimePlace: 1,
      },
      {
        user: {
          id: "dm1LPWjAkjoEnVx6",
          name: "khufufoofoo",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/khufufoofoo",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H57M1.385328S",
        finishTimeSeconds: 7021,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-14T20:01:37.641Z"),
    racetimeId: "oot/vanilla-leever-7932",
  },
  {
    id: "7daeacd6-edde-4e85-877b-a7da5a7b3bd6",
    entrants: [
      {
        user: {
          id: "wezlNoA4443mq6db",
          name: "PaintSkate8",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/drawing_of_me.jpg",
          twitchChannel: "https://www.twitch.tv/paintskate8",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H49M27.381867S",
        finishTimeSeconds: 6567,
        racetimePlace: 2,
      },
      {
        user: {
          id: "R8QGZrB2k03Ngk4V",
          name: "Challensois_",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/Malon_copy.png",
          twitchChannel: "https://www.twitch.tv/challensois_",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H18M57.825806S",
        finishTimeSeconds: 4737,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-14T16:07:44.009Z"),
    racetimeId: "oot/crafty-knuckle-6930",
  },
  {
    id: "9d037136-921c-44f9-93aa-1ec8f8a7d241",
    entrants: [
      {
        user: {
          id: "ZbpNAaBvn5BJkg04",
          name: "Exodus",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/bottleblack_skXfQZO.png",
          twitchChannel: "https://www.twitch.tv/exodus122",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H11M43.027025S",
        finishTimeSeconds: 4303,
        racetimePlace: 2,
      },
      {
        user: {
          id: "R8QGZrB2q0WNgk4V",
          name: "Cloudike",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/profil_twitch_cloudike_250.png",
          twitchChannel: "https://www.twitch.tv/cloudike",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H27M13.011623S",
        finishTimeSeconds: 5233,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-14T16:00:22.965Z"),
    racetimeId: "oot/shiny-morpha-2451",
  },
  {
    id: "e4a76a44-db8a-470d-aa76-b2b8ce762be2",
    entrants: [
      {
        user: {
          id: "Aa5veoGybABMVr6Z",
          name: "triforce3250",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/ZeldaRacetimeThumbnail.jpg",
          twitchChannel: "https://www.twitch.tv/triforce3250",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H16M15.295992S",
        finishTimeSeconds: 4575,
        racetimePlace: 2,
      },
      {
        user: {
          id: "7lYZa5B5eZB2Vwv9",
          name: "MutantAura",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/5454518169c837a73da4f74202a5960a.webp",
          twitchChannel: "https://www.twitch.tv/mutantaura",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H17M55.652981S",
        finishTimeSeconds: 4675,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-23T19:03:02.696Z"),
    racetimeId: "oot/shiny-shadow-1698",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
    vodUrl: "https://youtu.be/97LEagrH0Qw",
  },
  {
    id: "e7c92dfb-cb07-48b6-9f87-8c8cb508d54e",
    entrants: [
      {
        user: {
          id: "MqzQPW4Nam31L2R5",
          name: "JEANBERNARDGAMING",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/884103729182289981.webp",
          twitchChannel: "https://www.twitch.tv/xxm4dg4m3r54xx",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H56M7.072443S",
        finishTimeSeconds: 6967,
        racetimePlace: 1,
      },
      {
        user: {
          id: "JXzVwZWqElW5k8eb",
          name: "the__consultant",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/Excel_Face_257.jpg",
          twitchChannel: "https://www.twitch.tv/the__consultant",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H18M7.926846S",
        finishTimeSeconds: 4687,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-26T14:02:30.332Z"),
    racetimeId: "oot/eager-ganon-8445",
  },
  {
    id: "090ace8d-9135-455c-96a9-c71decd42187",
    entrants: [
      {
        user: {
          id: "JXzVwZWqElW5k8eb",
          name: "the__consultant",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/Excel_Face_257.jpg",
          twitchChannel: "https://www.twitch.tv/the__consultant",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H28M3.696969S",
        finishTimeSeconds: 5283,
        racetimePlace: 2,
      },
      {
        user: {
          id: "Aa5veoGybABMVr6Z",
          name: "triforce3250",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/ZeldaRacetimeThumbnail.jpg",
          twitchChannel: "https://www.twitch.tv/triforce3250",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H12M6.992082S",
        finishTimeSeconds: 4326,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-08-29T00:07:11.264Z"),
    racetimeId: "oot/prudent-colossus-3745",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/jmiXPLUdvXk",
  },
  {
    id: "d03a51d7-fb68-4eaf-8d68-9fe74f6b6e8f",
    entrants: [
      {
        user: {
          id: "yMewn83V89W405Jv",
          name: "PsyMarth",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/logo_100px.png",
          twitchChannel: "https://www.twitch.tv/psymarth",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H38M2.477107S",
        finishTimeSeconds: 5882,
        racetimePlace: 2,
      },
      {
        user: {
          id: "Ek8wpok9GkB5KQyV",
          name: "neefe",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/ivysquare.png",
          twitchChannel: "https://www.twitch.tv/neefe",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H23M35.971605S",
        finishTimeSeconds: 5015,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-15T22:06:01.642Z"),
    racetimeId: "oot/sunken-skullkid-0719",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "aGklxjWzQvoLPdye",
      name: "noface099",
      roles: [Role.Admin, Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/face_saulte_56.png",
      twitchChannel: "https://www.twitch.tv/noface099",
    },
  },
  {
    id: "41a11e75-cc8e-488d-bd72-98772aa810e5",
    entrants: [
      {
        user: {
          id: "dm1LPWjAkjoEnVx6",
          name: "khufufoofoo",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/khufufoofoo",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H57M54.901901S",
        finishTimeSeconds: 7074,
        racetimePlace: 2,
      },
      {
        user: {
          id: "OR6ym83myb3Pd1Xr",
          name: "HappyHikerPhilippeTitou",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/Sans_titre-removebg-preview_4e6yOAB.png",
          twitchChannel: "https://www.twitch.tv/p_titou",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H27M39.440609S",
        finishTimeSeconds: 5259,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-23T19:01:40.468Z"),
    racetimeId: "oot/amused-bosskey-2568",
  },
  {
    id: "267126f9-6465-4273-94e3-fce44c47b8d9",
    entrants: [
      {
        user: {
          id: "jQbq4dBpey3vlrG0",
          name: "MooseOoT",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/5819539783680",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H16M29.264225S",
        finishTimeSeconds: 4589,
        racetimePlace: 1,
      },
      {
        user: {
          id: "Ek8wpok9KVB5KQyV",
          name: "Countdown",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/steiner_HD.png",
          twitchChannel: "https://www.twitch.tv/countdown69",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H44M18.516898S",
        finishTimeSeconds: 6258,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-24T00:00:24.693Z"),
    racetimeId: "oot/outrageous-requiem-7510",
  },
  {
    id: "441114a4-4f16-460e-ac39-49839770e0b0",
    entrants: [
      {
        user: {
          id: "xldAMBl4A4BaOP57",
          name: "Gombill",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/gombill",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H30M23.848132S",
        finishTimeSeconds: 5423,
        racetimePlace: 1,
      },
      {
        user: {
          id: "jQbq4dBp7yWvlrG0",
          name: "Link11",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/Link-1.png",
          twitchChannel: "https://www.twitch.tv/link__11",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H11M7.566142S",
        finishTimeSeconds: 4267,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-17T02:05:21.168Z"),
    racetimeId: "oot/grumpy-peahat-4446",
  },
  {
    id: "55d189b6-c35b-4e3e-b1b2-8665ab86a780",
    entrants: [
      {
        user: {
          id: "OR6ym83mvqoPd1Xr",
          name: "LadyLambdadeltasDandruff",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/images_m4K3UXM.jpg",
          twitchChannel: "https://www.twitch.tv/clairelynnd",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H33M33.041635S",
        finishTimeSeconds: 5613,
        racetimePlace: 2,
      },
      {
        user: {
          id: "LxldAMBlnboaOP57",
          name: "LiterallyLake",
          roles: [Role.Entrant, Role.Restreamer],
          twitchChannel: "https://www.twitch.tv/literallylake",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H13M25.938649S",
        finishTimeSeconds: 4405,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-19T01:00:42.252Z"),
    racetimeId: "oot/gorgeous-kingzora-3326",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "aGklxjWzQvoLPdye",
      name: "noface099",
      roles: [Role.Admin, Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/face_saulte_56.png",
      twitchChannel: "https://www.twitch.tv/noface099",
    },
    vodUrl: "https://youtu.be/vjdEqmDy6RU",
  },
  {
    id: "5561a6b6-7e00-4b13-8d52-2d55f46864ca",
    entrants: [
      {
        user: {
          id: "jQbq4dBpey3vlrG0",
          name: "MooseOoT",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/5819539783680",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H13M24.7943S",
        finishTimeSeconds: 4404,
        racetimePlace: 1,
      },
      {
        user: {
          id: "52QE8oNlGXBlywqX",
          name: "Grego",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/purplepixel_DXDp79m.png",
          twitchChannel: "https://www.twitch.tv/07151129",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H24M18.68744S",
        finishTimeSeconds: 5058,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-17T00:36:13.821Z"),
    racetimeId: "oot/amused-emerald-6088",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "aGklxjWzQvoLPdye",
      name: "noface099",
      roles: [Role.Admin, Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/face_saulte_56.png",
      twitchChannel: "https://www.twitch.tv/noface099",
    },
    vodUrl: "https://youtu.be/HvZ_Tm2Rcrw",
  },
  {
    id: "6e99ae36-9517-4ead-8df2-8caa8f09aac1",
    entrants: [
      {
        user: {
          id: "Gzr7pBM7dyokqgyE",
          name: "Amber24",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/amber24",
        },
        state: EntrantState.Finished,
        finishTime: "PT2H31M27.742866S",
        finishTimeSeconds: 9087,
        racetimePlace: 2,
      },
      {
        user: {
          id: "52QE8oNlGXBlywqX",
          name: "Grego",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/purplepixel_DXDp79m.png",
          twitchChannel: "https://www.twitch.tv/07151129",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H22M45.345599S",
        finishTimeSeconds: 4965,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-22T00:05:04.500Z"),
    racetimeId: "oot/eager-lullaby-6715",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
    vodUrl: "https://youtu.be/PuWe7k9MG_I",
  },
  {
    id: "90a53b26-35c2-45ba-b2b7-58ac76ee9e40",
    entrants: [
      {
        user: {
          id: "MqzQPW4Nam31L2R5",
          name: "JEANBERNARDGAMING",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/884103729182289981.webp",
          twitchChannel: "https://www.twitch.tv/xxm4dg4m3r54xx",
        },
        state: EntrantState.Finished,
        finishTime: "PT2H7M0.267408S",
        finishTimeSeconds: 7620,
        racetimePlace: 2,
      },
      {
        user: {
          id: "Aa5veoGybABMVr6Z",
          name: "triforce3250",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/ZeldaRacetimeThumbnail.jpg",
          twitchChannel: "https://www.twitch.tv/triforce3250",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H20M53.117853S",
        finishTimeSeconds: 4853,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-17T23:04:03.821Z"),
    racetimeId: "oot/cunning-cow-8416",
  },
  {
    id: "5cd7398a-68eb-4bac-86d8-b8dd428f975e",
    entrants: [
      {
        user: {
          id: "VXY0eABdn7oLKPnz",
          name: "MatttInTheHat",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/5fd36485885522315a35606b3a269af6.jpg",
          twitchChannel: "https://www.twitch.tv/matttinthehat",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H35M21.835176S",
        finishTimeSeconds: 5721,
        racetimePlace: 1,
      },
      {
        user: {
          id: "wNZ1KRBOV8W4qAyj",
          name: "tob3000",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/tob3000",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H22M32.756123S",
        finishTimeSeconds: 4952,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-17T22:07:25.461Z"),
    racetimeId: "oot/banzai-spookymask-7915",
  },
  {
    id: "724645be-9610-4833-9b7f-45297503bd5e",
    entrants: [
      {
        user: {
          id: "vrZyM4orbEoqDJX0",
          name: "Fenyan",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/Ava.png",
          twitchChannel: "https://www.twitch.tv/fenyan",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H28M6.897324S",
        finishTimeSeconds: 5286,
        racetimePlace: 2,
      },
      {
        user: {
          id: "kzM65aWX6b31y8q0",
          name: "Nalle",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/gator2gif.gif",
          twitchChannel: "https://www.twitch.tv/nallesounds",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H19M50.555007S",
        finishTimeSeconds: 4790,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-17T18:12:10.640Z"),
    racetimeId: "oot/trusty-nayru-6831",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "5rNGD3DKVaB9blOy",
      name: "FantaTanked",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/jimmyW.png",
      twitchChannel: "https://www.twitch.tv/fantatanked",
    },
  },
  {
    id: "957f88df-4ed2-4c16-ab70-6e0a84d62c19",
    entrants: [
      {
        user: {
          id: "ZbpNAaBvn5BJkg04",
          name: "Exodus",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/bottleblack_skXfQZO.png",
          twitchChannel: "https://www.twitch.tv/exodus122",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H10M44.82796S",
        finishTimeSeconds: 4244,
        racetimePlace: 2,
      },
      {
        user: {
          id: "R8QGZrB2k03Ngk4V",
          name: "Challensois_",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/Malon_copy.png",
          twitchChannel: "https://www.twitch.tv/challensois_",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H18M0.083662S",
        finishTimeSeconds: 4680,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-26T16:03:55.749Z"),
    racetimeId: "oot/superb-armos-1304",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "aGklxjWzQvoLPdye",
      name: "noface099",
      roles: [Role.Admin, Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/face_saulte_56.png",
      twitchChannel: "https://www.twitch.tv/noface099",
    },
    vodUrl: "https://youtu.be/FS39m_mvv00",
  },
  {
    id: "bd3cdcff-4e13-4cf1-af84-880a33ae90c7",
    entrants: [
      {
        user: {
          id: "wdm1LPWjAoEnVx6k",
          name: "dotzo",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/dotzo_-_black_aO9qZEm.png",
          twitchChannel: "https://www.twitch.tv/dotzo",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H13M38.484465S",
        finishTimeSeconds: 4418,
        racetimePlace: 2,
      },
      {
        user: {
          id: "LNY0OkW1OP3KalP1",
          name: "CoffeePot",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/coff.png",
          twitchChannel: "https://www.twitch.tv/coffeepot",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H25M27.302004S",
        finishTimeSeconds: 5127,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-23T21:10:50.390Z"),
    racetimeId: "oot/tasty-boomerang-5896",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "VXY0eABddNBLKPnz",
      name: "shiroaeli",
      roles: [Role.Admin, Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/shiro_susan.png",
      twitchChannel: "https://www.twitch.tv/shiroaeli",
    },
    vodUrl: "https://youtu.be/6Uk-FX3XXls",
  },
  {
    id: "967f3a3f-9a82-4e73-a289-77238b8645aa",
    entrants: [
      {
        user: {
          id: "NX5783JddGWqlL0a",
          name: "moosecrap",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/newest_avatar.png",
          twitchChannel: "https://www.twitch.tv/moosecrap",
        },
        state: EntrantState.Finished,
        finishTime: "PT2H1M51.74568S",
        finishTimeSeconds: 7311,
        racetimePlace: 2,
      },
      {
        user: {
          id: "DMLq1oZ98e3OeQG8",
          name: "Eggmeister",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/egg2_2.png",
          twitchChannel: "https://www.twitch.tv/eggmeister321",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H13M1.412775S",
        finishTimeSeconds: 4381,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-19T01:31:03.063Z"),
    racetimeId: "oot/pogtastic-quiver-4892",
  },
  {
    id: "0b44a2a6-1f12-4f23-b052-ea8130e8908a",
    entrants: [
      {
        user: {
          id: "Gzr7pBM7dyokqgyE",
          name: "Amber24",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/amber24",
        },
        state: EntrantState.Finished,
        finishTime: "PT3H6M9.105386S",
        finishTimeSeconds: 11169,
        racetimePlace: 2,
      },
      {
        user: {
          id: "Ek8wpok9KVB5KQyV",
          name: "Countdown",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/steiner_HD.png",
          twitchChannel: "https://www.twitch.tv/countdown69",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H44M10.408405S",
        finishTimeSeconds: 6250,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-17T23:31:47.198Z"),
    racetimeId: "oot/gnarly-goron-9827",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "aGklxjWzQvoLPdye",
      name: "noface099",
      roles: [Role.Admin, Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/face_saulte_56.png",
      twitchChannel: "https://www.twitch.tv/noface099",
    },
    vodUrl: "https://youtu.be/_BWIVo-Uej0",
  },
  {
    id: "3850702b-3470-4c94-9c9c-384c6385cd7e",
    entrants: [
      {
        user: {
          id: "VXY0eABddNBLKPnz",
          name: "shiroaeli",
          roles: [Role.Admin, Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/shiro_susan.png",
          twitchChannel: "https://www.twitch.tv/shiroaeli",
        },
        state: EntrantState.DidNotFinish,
      },
      {
        user: {
          id: "LNY0OkW1OP3KalP1",
          name: "CoffeePot",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/coff.png",
          twitchChannel: "https://www.twitch.tv/coffeepot",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H32M23.350453S",
        finishTimeSeconds: 5543,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-16T23:03:22.763Z"),
    racetimeId: "oot/priceless-bracelet-5632",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "aGklxjWzQvoLPdye",
      name: "noface099",
      roles: [Role.Admin, Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/face_saulte_56.png",
      twitchChannel: "https://www.twitch.tv/noface099",
    },
    vodUrl: "https://youtu.be/6l2pNIf2R6Q",
  },
  {
    id: "583a88b0-b26b-4d4c-b019-31df4b2a22f8",
    entrants: [
      {
        user: {
          id: "VXY0eABd6boLKPnz",
          name: "shaggy",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/shaggy3311",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H33M51.077026S",
        finishTimeSeconds: 5631,
        racetimePlace: 2,
      },
      {
        user: {
          id: "Ek8wpok9GkB5KQyV",
          name: "neefe",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/ivysquare.png",
          twitchChannel: "https://www.twitch.tv/neefe",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H18M48.583478S",
        finishTimeSeconds: 4728,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-08-28T21:57:33.947Z"),
    racetimeId: "oot/kind-ruby-3059",
  },
  {
    id: "40887143-117c-418b-87c3-9303724aeb18",
    entrants: [
      {
        user: {
          id: "dm1LPWjZLLWEnVx6",
          name: "Cabbage72",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/20220726_155822.jpg",
          twitchChannel: "https://www.twitch.tv/cabbage72",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H27M18.684264S",
        finishTimeSeconds: 5238,
        racetimePlace: 2,
      },
      {
        user: {
          id: "Qbq4dBpJnrovlrG0",
          name: "skepticole",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/skepticole",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H22M0.643358S",
        finishTimeSeconds: 4920,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-25T00:03:33.475Z"),
    racetimeId: "oot/fancy-freezard-4839",
  },
  {
    id: "775614e5-bd24-49a8-bf0c-7b9ddb78dc0d",
    entrants: [
      {
        user: {
          id: "yMewn83V89W405Jv",
          name: "PsyMarth",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/logo_100px.png",
          twitchChannel: "https://www.twitch.tv/psymarth",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H49M3.007063S",
        finishTimeSeconds: 6543,
        racetimePlace: 2,
      },
      {
        user: {
          id: "VXY0eABd6boLKPnz",
          name: "shaggy",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/shaggy3311",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H36M54.342912S",
        finishTimeSeconds: 5814,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-24T22:20:37.989Z"),
    racetimeId: "oot/snug-skulltula-0124",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/BXbzHuu5FiE",
  },
  {
    id: "78d77f47-e5bf-4d69-82e5-a1597fbcb68e",
    entrants: [
      {
        user: {
          id: "Ek8wpok9GkB5KQyV",
          name: "neefe",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/ivysquare.png",
          twitchChannel: "https://www.twitch.tv/neefe",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H24M27.36777S",
        finishTimeSeconds: 5067,
        racetimePlace: 2,
      },
      {
        user: {
          id: "Va0eMongz6Wl9pyJ",
          name: "2DollarGargoyle",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/poe_triforce_better.png",
          twitchChannel: "https://www.twitch.tv/2dollargargoyle",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H14M10.44626S",
        finishTimeSeconds: 4450,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-24T22:15:58.433Z"),
    racetimeId: "oot/adequate-freezard-5044",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/BXbzHuu5FiE",
  },
  {
    id: "77ec3c9f-7547-425b-bf08-6f100e6cd98b",
    entrants: [
      {
        user: {
          id: "NX5783JddGWqlL0a",
          name: "moosecrap",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/newest_avatar.png",
          twitchChannel: "https://www.twitch.tv/moosecrap",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H33M8.647505S",
        finishTimeSeconds: 5588,
        racetimePlace: 2,
      },
      {
        user: {
          id: "dm1LPWj2DOWEnVx6",
          name: "Darker",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/UjEwSk6G_400x400.jpg",
          twitchChannel: "https://www.twitch.tv/darkerandroid",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H36M22.427596S",
        finishTimeSeconds: 5782,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-26T01:38:09.661Z"),
    racetimeId: "oot/jolly-mushroom-8514",
  },
  {
    id: "6629cfaf-2109-43a1-be25-6664a450537a",
    entrants: [
      {
        user: {
          id: "xldAMBl4A4BaOP57",
          name: "Gombill",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/gombill",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H37M57.453062S",
        finishTimeSeconds: 5877,
        racetimePlace: 2,
      },
      {
        user: {
          id: "LxldAMBlnboaOP57",
          name: "LiterallyLake",
          roles: [Role.Entrant, Role.Restreamer],
          twitchChannel: "https://www.twitch.tv/literallylake",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H9M38.373866S",
        finishTimeSeconds: 4178,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-22T22:01:13.075Z"),
    racetimeId: "oot/mysterious-hookshot-7078",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/JMy_GHGK9Zg",
  },
  {
    id: "85a7d8a6-c1ca-44ee-a0aa-f4baa010e0ed",
    entrants: [
      {
        user: {
          id: "ZVa0eMonnbol9pyJ",
          name: "adef",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/adef.png",
          twitchChannel: "https://www.twitch.tv/adef",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H31M12.943647S",
        finishTimeSeconds: 5472,
        racetimePlace: 2,
      },
      {
        user: {
          id: "vrZyM4orbEoqDJX0",
          name: "Fenyan",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/Ava.png",
          twitchChannel: "https://www.twitch.tv/fenyan",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H28M41.657419S",
        finishTimeSeconds: 5321,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-08-29T21:32:12.498Z"),
    racetimeId: "oot/outrageous-arrow-0473",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
    vodUrl: "https://youtu.be/S_wWIIHmS_c",
  },
  {
    id: "49e0d538-a923-497f-b27a-a2732e69f9bf",
    entrants: [
      {
        user: {
          id: "vrZyM4orqE3qDJX0",
          name: "Woli",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/5e9d035f8ad1c385bf6db77cb5761628.jpg",
          twitchChannel: "https://www.twitch.tv/wolisecondary",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H15M57.035416S",
        finishTimeSeconds: 4557,
        racetimePlace: 1,
      },
      {
        user: {
          id: "XGzr7pBMyqBkqgyE",
          name: "TomPouce",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/yeeeeeh.jpg",
          twitchChannel: "https://www.twitch.tv/originaltompouce",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H33M57.223558S",
        finishTimeSeconds: 5637,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-21T19:31:56.060Z"),
    racetimeId: "oot/fancy-bongobongo-8754",
  },
  {
    id: "e9b3a60e-2ecb-4d5d-becc-dd7ddba264ea",
    entrants: [
      {
        user: {
          id: "R8QGZrB2q0WNgk4V",
          name: "Cloudike",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/profil_twitch_cloudike_250.png",
          twitchChannel: "https://www.twitch.tv/cloudike",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H39M18.162978S",
        finishTimeSeconds: 5958,
        racetimePlace: 2,
      },
      {
        user: {
          id: "wezlNoA4443mq6db",
          name: "PaintSkate8",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/drawing_of_me.jpg",
          twitchChannel: "https://www.twitch.tv/paintskate8",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H41M34.983114S",
        finishTimeSeconds: 6094,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-21T18:16:35.807Z"),
    racetimeId: "oot/epic-saria-0643",
  },
  {
    id: "ceb96af7-9766-44bc-921f-aa3ed443fb88",
    entrants: [
      {
        user: {
          id: "ZVa0eMonnbol9pyJ",
          name: "adef",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/adef.png",
          twitchChannel: "https://www.twitch.tv/adef",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H25M30.821334S",
        finishTimeSeconds: 5130,
        racetimePlace: 1,
      },
      {
        user: {
          id: "kzM65aWX6b31y8q0",
          name: "Nalle",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/gator2gif.gif",
          twitchChannel: "https://www.twitch.tv/nallesounds",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H34M2.714328S",
        finishTimeSeconds: 5642,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-21T18:03:28.875Z"),
    racetimeId: "oot/fortunate-fire-3972",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "5rNGD3DKVaB9blOy",
      name: "FantaTanked",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/jimmyW.png",
      twitchChannel: "https://www.twitch.tv/fantatanked",
    },
  },
  {
    id: "6e0aa800-31c7-4a65-8556-cdd4992b1a71",
    entrants: [
      {
        user: {
          id: "dm1LPWjZLLWEnVx6",
          name: "Cabbage72",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/20220726_155822.jpg",
          twitchChannel: "https://www.twitch.tv/cabbage72",
        },
        state: EntrantState.DidNotFinish,
      },
      {
        user: {
          id: "rZyM4orRvRoqDJX0",
          name: "jenslang",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/e0aaf3c8688abd0e58bed9d9f63de4ad.png",
          twitchChannel: "https://www.twitch.tv/jenslang",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H18M59.360908S",
        finishTimeSeconds: 4739,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-09-04T03:16:47.835Z"),
    racetimeId: "oot/vanilla-guay-4543",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "8QGZrB2bNRBNgk4V",
      name: "katienoelle",
      roles: [Role.Restreamer],
      avatar: "https://racetime.gg/media/image_ABtrMoN.png",
      twitchChannel: "https://www.twitch.tv/katienoeiie",
    },
    vodUrl: "https://youtu.be/nznX1toftBo",
  },
  {
    id: "2d4510d3-5c10-4c10-80bd-b85392718925",
    entrants: [
      {
        user: {
          id: "NqO2YoLDL8o9QEya",
          name: "Jake Wright",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/flat750x075f-pad750x1000f8f8f8.png",
          twitchChannel: "https://www.twitch.tv/jakewrlght",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H13M14.35852S",
        finishTimeSeconds: 4394,
        racetimePlace: 1,
      },
      {
        user: {
          id: "VXY0eABdn7oLKPnz",
          name: "MatttInTheHat",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/5fd36485885522315a35606b3a269af6.jpg",
          twitchChannel: "https://www.twitch.tv/matttinthehat",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H29M50.048892S",
        finishTimeSeconds: 5390,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-08-31T18:04:56.960Z"),
    racetimeId: "oot/perfect-twinrova-3106",
  },
  {
    id: "50175fa0-2b0d-4f4b-8810-9bf102ea189b",
    entrants: [
      {
        user: {
          id: "VXY0eABddNBLKPnz",
          name: "shiroaeli",
          roles: [Role.Admin, Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/shiro_susan.png",
          twitchChannel: "https://www.twitch.tv/shiroaeli",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H52M21.407335S",
        finishTimeSeconds: 6741,
        racetimePlace: 1,
      },
      {
        user: {
          id: "yMewn83V613405Jv",
          name: "TKC",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/qYgfSWJ__400x400.jpg",
          twitchChannel: "https://www.twitch.tv/tkc014",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H6M55.744791S",
        finishTimeSeconds: 4015,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-26T00:00:16.851Z"),
    racetimeId: "oot/famous-hylian-5260",
  },
  {
    id: "eaa15313-9c05-49a0-ac80-0a1c66c9cf0e",
    entrants: [
      {
        user: {
          id: "OR6ym83mvqoPd1Xr",
          name: "LadyLambdadeltasDandruff",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/images_m4K3UXM.jpg",
          twitchChannel: "https://www.twitch.tv/clairelynnd",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H33M18.416662S",
        finishTimeSeconds: 5598,
        racetimePlace: 1,
      },
      {
        user: {
          id: "xldAMBl4A4BaOP57",
          name: "Gombill",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/gombill",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H41M5.716945S",
        finishTimeSeconds: 6065,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-08-30T02:03:35.516Z"),
    racetimeId: "oot/swag-octorok-5259",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "aGklxjWzQvoLPdye",
      name: "noface099",
      roles: [Role.Admin, Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/face_saulte_56.png",
      twitchChannel: "https://www.twitch.tv/noface099",
    },
    vodUrl: "https://youtu.be/X9koDcjuqpA",
  },
  {
    id: "71d90ea0-fc2d-46f7-b31a-9cb161d0a14a",
    entrants: [
      {
        user: {
          id: "OR6ym83mnjoPd1Xr",
          name: "Amateseru",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/amateseru",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H16M1.269071S",
        finishTimeSeconds: 4561,
        racetimePlace: 2,
      },
      {
        user: {
          id: "d17DexWEkR3ak64R",
          name: "gsk8",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/teteaucarr%C3%A93112.png",
          twitchChannel: "https://www.twitch.tv/gsk8",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H25M22.13139S",
        finishTimeSeconds: 5122,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-25T17:36:47.355Z"),
    racetimeId: "oot/crafty-nocturne-1504",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "NJrM6PoY5AoRdm5v",
      name: "Yanis",
      roles: [Role.Restreamer],
      avatar: "https://racetime.gg/media/yhey.png",
      twitchChannel: "https://www.twitch.tv/yanis2",
    },
  },
  {
    id: "346d13a6-e176-4c80-9ecf-22099b462e4e",
    entrants: [
      {
        user: {
          id: "dm1LPWj2DOWEnVx6",
          name: "Darker",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/UjEwSk6G_400x400.jpg",
          twitchChannel: "https://www.twitch.tv/darkerandroid",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H24M12.602104S",
        finishTimeSeconds: 5052,
        racetimePlace: 2,
      },
      {
        user: {
          id: "DMLq1oZ98e3OeQG8",
          name: "Eggmeister",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/egg2_2.png",
          twitchChannel: "https://www.twitch.tv/eggmeister321",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H13M42.513923S",
        finishTimeSeconds: 4422,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-09-02T18:01:31.011Z"),
    racetimeId: "oot/gorgeous-kotake-7091",
  },
  {
    id: "153e160c-05ff-4d50-a1e4-98c4edc5a59d",
    entrants: [
      {
        user: {
          id: "Gzr7pBM7dyokqgyE",
          name: "Amber24",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/amber24",
        },
        state: EntrantState.DidNotFinish,
      },
      {
        user: {
          id: "jQbq4dBpey3vlrG0",
          name: "MooseOoT",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/5819539783680",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H14M52.46876S",
        finishTimeSeconds: 4492,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-08-31T23:33:05.593Z"),
    racetimeId: "oot/fancy-bow-4746",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "aGklxjWzQvoLPdye",
      name: "noface099",
      roles: [Role.Admin, Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/face_saulte_56.png",
      twitchChannel: "https://www.twitch.tv/noface099",
    },
    vodUrl: "https://youtu.be/oRozh34bxoY",
  },
  {
    id: "68d5e73f-5e2f-4b4a-9989-737ccc227db3",
    entrants: [
      {
        user: {
          id: "wdm1LPWjAoEnVx6k",
          name: "dotzo",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/dotzo_-_black_aO9qZEm.png",
          twitchChannel: "https://www.twitch.tv/dotzo",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H17M15.942952S",
        finishTimeSeconds: 4635,
        racetimePlace: 1,
      },
      {
        user: {
          id: "VXY0eABddNBLKPnz",
          name: "shiroaeli",
          roles: [Role.Admin, Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/shiro_susan.png",
          twitchChannel: "https://www.twitch.tv/shiroaeli",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H47M39.453711S",
        finishTimeSeconds: 6459,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-08-28T21:19:28.715Z"),
    racetimeId: "oot/quick-skulltula-1660",
  },
  {
    id: "67956205-76bd-4a1f-adb6-49684c27499f",
    entrants: [
      {
        user: {
          id: "yMewn83V613405Jv",
          name: "TKC",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/qYgfSWJ__400x400.jpg",
          twitchChannel: "https://www.twitch.tv/tkc014",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H17M32.313836S",
        finishTimeSeconds: 4652,
        racetimePlace: 2,
      },
      {
        user: {
          id: "LNY0OkW1OP3KalP1",
          name: "CoffeePot",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/coff.png",
          twitchChannel: "https://www.twitch.tv/coffeepot",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H29M1.0188S",
        finishTimeSeconds: 5341,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-08-30T12:03:12.085Z"),
    racetimeId: "oot/salty-bottle-3112",
  },
  {
    id: "3908c7f8-16ca-45c1-a8cd-3a0bdca7f2c7",
    entrants: [
      {
        user: {
          id: "yMewn83V89W405Jv",
          name: "PsyMarth",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/logo_100px.png",
          twitchChannel: "https://www.twitch.tv/psymarth",
        },
        state: EntrantState.DidNotFinish,
      },
      {
        user: {
          id: "Va0eMongz6Wl9pyJ",
          name: "2DollarGargoyle",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/poe_triforce_better.png",
          twitchChannel: "https://www.twitch.tv/2dollargargoyle",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H6M21.863806S",
        finishTimeSeconds: 3981,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-09-02T22:33:49.447Z"),
    racetimeId: "oot/crafty-kokiri-5738",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "aGklxjWzQvoLPdye",
      name: "noface099",
      roles: [Role.Admin, Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/face_saulte_56.png",
      twitchChannel: "https://www.twitch.tv/noface099",
    },
    vodUrl: "https://youtu.be/SfQYqRO_KZs",
  },
  {
    id: "0ad1a09a-c4b6-49e2-95c6-e5471c1d3dfc",
    entrants: [
      {
        user: {
          id: "R8QGZrB2q0WNgk4V",
          name: "Cloudike",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/profil_twitch_cloudike_250.png",
          twitchChannel: "https://www.twitch.tv/cloudike",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H41M17.739058S",
        finishTimeSeconds: 6077,
        racetimePlace: 2,
      },
      {
        user: {
          id: "R8QGZrB2k03Ngk4V",
          name: "Challensois_",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/Malon_copy.png",
          twitchChannel: "https://www.twitch.tv/challensois_",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H23M47.698332S",
        finishTimeSeconds: 5027,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-08-29T18:03:26.579Z"),
    racetimeId: "oot/clever-sword-0889",
  },
  {
    id: "756d6cd7-b41c-44ad-8845-a74f19711e09",
    entrants: [
      {
        user: {
          id: "MqzQPW4Nam31L2R5",
          name: "JEANBERNARDGAMING",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/884103729182289981.webp",
          twitchChannel: "https://www.twitch.tv/xxm4dg4m3r54xx",
        },
        state: EntrantState.DidNotFinish,
      },
      {
        user: {
          id: "7lYZa5B5eZB2Vwv9",
          name: "MutantAura",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/5454518169c837a73da4f74202a5960a.webp",
          twitchChannel: "https://www.twitch.tv/mutantaura",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H11M27.549415S",
        finishTimeSeconds: 4287,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-09-01T18:04:44.768Z"),
    racetimeId: "oot/clean-rupee-1257",
  },
  {
    id: "71958a16-fcf3-40a9-bc41-ef133e1d0e33",
    entrants: [
      {
        user: {
          id: "vrZyM4orqE3qDJX0",
          name: "Woli",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/5e9d035f8ad1c385bf6db77cb5761628.jpg",
          twitchChannel: "https://www.twitch.tv/wolisecondary",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H28M51.296254S",
        finishTimeSeconds: 5331,
        racetimePlace: 1,
      },
      {
        user: {
          id: "OR6ym83myb3Pd1Xr",
          name: "HappyHikerPhilippeTitou",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/Sans_titre-removebg-preview_4e6yOAB.png",
          twitchChannel: "https://www.twitch.tv/p_titou",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H13M19.504664S",
        finishTimeSeconds: 4399,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-08-28T17:30:18.786Z"),
    racetimeId: "oot/shiny-morpha-9452",
  },
  {
    id: "eb06bf69-da7a-4e9c-aaa7-d599c3343532",
    entrants: [
      {
        user: {
          id: "52QE8oNlGXBlywqX",
          name: "Grego",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/purplepixel_DXDp79m.png",
          twitchChannel: "https://www.twitch.tv/07151129",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H16M41.28086S",
        finishTimeSeconds: 4601,
        racetimePlace: 2,
      },
      {
        user: {
          id: "Ek8wpok9KVB5KQyV",
          name: "Countdown",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/steiner_HD.png",
          twitchChannel: "https://www.twitch.tv/countdown69",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H42M59.944852S",
        finishTimeSeconds: 6179,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-09-01T02:01:50.686Z"),
    racetimeId: "oot/lurking-dinolfos-2304",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "aGklxjWzQvoLPdye",
      name: "noface099",
      roles: [Role.Admin, Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/face_saulte_56.png",
      twitchChannel: "https://www.twitch.tv/noface099",
    },
    vodUrl: "https://youtu.be/FRn0RW1WLDo",
  },
  {
    id: "dbeb0af7-1dad-4687-b1ae-f001828a1832",
    entrants: [
      {
        user: {
          id: "NX5783JddGWqlL0a",
          name: "moosecrap",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/newest_avatar.png",
          twitchChannel: "https://www.twitch.tv/moosecrap",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H41M30.905828S",
        finishTimeSeconds: 6090,
        racetimePlace: 2,
      },
      {
        user: {
          id: "5rNGD3DKVaB9blOy",
          name: "FantaTanked",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/jimmyW.png",
          twitchChannel: "https://www.twitch.tv/fantatanked",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H10M51.184353S",
        finishTimeSeconds: 4251,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-09-02T19:06:30.762Z"),
    racetimeId: "oot/magnificent-pocketegg-5300",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "aGklxjWzQvoLPdye",
      name: "noface099",
      roles: [Role.Admin, Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/face_saulte_56.png",
      twitchChannel: "https://www.twitch.tv/noface099",
    },
    vodUrl: "https://youtu.be/aBQjeUMr8WQ",
  },
  {
    id: "874bde8d-8757-4d14-a61c-0c236a69f4f4",
    entrants: [
      {
        user: {
          id: "ZbpNAaBvn5BJkg04",
          name: "Exodus",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/bottleblack_skXfQZO.png",
          twitchChannel: "https://www.twitch.tv/exodus122",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H10M47.582922S",
        finishTimeSeconds: 4247,
        racetimePlace: 1,
      },
      {
        user: {
          id: "wezlNoA4443mq6db",
          name: "PaintSkate8",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/drawing_of_me.jpg",
          twitchChannel: "https://www.twitch.tv/paintskate8",
        },
        state: EntrantState.DidNotFinish,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-08-31T01:09:05.399Z"),
    racetimeId: "oot/bonus-sheik-6740",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "VXY0eABddNBLKPnz",
      name: "shiroaeli",
      roles: [Role.Admin, Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/shiro_susan.png",
      twitchChannel: "https://www.twitch.tv/shiroaeli",
    },
  },
  {
    id: "dba355d4-969d-4602-8364-08da0fd819f7",
    entrants: [
      {
        user: {
          id: "OR6ym83mnjoPd1Xr",
          name: "Amateseru",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/amateseru",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H14M28.985846S",
        finishTimeSeconds: 4468,
        racetimePlace: 1,
      },
      {
        user: {
          id: "aGklxjWzQvoLPdye",
          name: "noface099",
          roles: [Role.Admin, Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/face_saulte_56.png",
          twitchChannel: "https://www.twitch.tv/noface099",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H32M22.405127S",
        finishTimeSeconds: 5542,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-08-30T17:10:38.151Z"),
    racetimeId: "oot/disco-ingo-2877",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "7lYZa5B5eZB2Vwv9",
      name: "MutantAura",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/5454518169c837a73da4f74202a5960a.webp",
      twitchChannel: "https://www.twitch.tv/mutantaura",
    },
  },
  {
    id: "4dafe401-ac11-4796-9f35-1e4b26aa41c9",
    entrants: [
      {
        user: {
          id: "XGzr7pBMyqBkqgyE",
          name: "TomPouce",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/yeeeeeh.jpg",
          twitchChannel: "https://www.twitch.tv/originaltompouce",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H29M54.786125S",
        finishTimeSeconds: 5394,
        racetimePlace: 1,
      },
      {
        user: {
          id: "dm1LPWjAkjoEnVx6",
          name: "khufufoofoo",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/khufufoofoo",
        },
        state: EntrantState.DidNotFinish,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-09-02T20:34:05.292Z"),
    racetimeId: "oot/brainy-twinrova-1671",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "Va0eMongz6Wl9pyJ",
      name: "2DollarGargoyle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/poe_triforce_better.png",
      twitchChannel: "https://www.twitch.tv/2dollargargoyle",
    },
    vodUrl: "https://youtu.be/FAZF7HqeZ08",
  },
  {
    id: "86a243be-b168-446e-aee9-54c86b6ace7a",
    entrants: [
      {
        user: {
          id: "d17DexWEkR3ak64R",
          name: "gsk8",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/teteaucarr%C3%A93112.png",
          twitchChannel: "https://www.twitch.tv/gsk8",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H16M30.387885S",
        finishTimeSeconds: 4590,
        racetimePlace: 2,
      },
      {
        user: {
          id: "kzM65aWXgxo1y8q0",
          name: "Runnerguy2489",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/Runnerguy.png",
          twitchChannel: "https://www.twitch.tv/runnerguy2489",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H16M4.365888S",
        finishTimeSeconds: 4564,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-09-03T17:06:56.216Z"),
    racetimeId: "oot/odd-boomerang-6334",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/tXqI3ppIDWg",
  },
  {
    id: "4f5c1a0e-d9f6-46c9-8269-67e42a609261",
    entrants: [
      {
        user: {
          id: "kzM65aWX6b31y8q0",
          name: "Nalle",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/gator2gif.gif",
          twitchChannel: "https://www.twitch.tv/nallesounds",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H25M47.179663S",
        finishTimeSeconds: 5147,
        racetimePlace: 2,
      },
      {
        user: {
          id: "LxldAMBlnboaOP57",
          name: "LiterallyLake",
          roles: [Role.Entrant, Role.Restreamer],
          twitchChannel: "https://www.twitch.tv/literallylake",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H14M46.68814S",
        finishTimeSeconds: 4486,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R1",
    scheduledTime: new Date("2023-09-20T18:34:44.964Z"),
    racetimeId: "oot/magic-leever-3973",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "5rNGD3DKVaB9blOy",
      name: "FantaTanked",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/jimmyW.png",
      twitchChannel: "https://www.twitch.tv/fantatanked",
    },
  },
  {
    id: "462c25ff-4761-49ab-a654-4e44864c13fb",
    entrants: [
      {
        user: {
          id: "ZbpNAaBvn5BJkg04",
          name: "Exodus",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/bottleblack_skXfQZO.png",
          twitchChannel: "https://www.twitch.tv/exodus122",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H13M15.139375S",
        finishTimeSeconds: 4395,
        racetimePlace: 2,
      },
      {
        user: {
          id: "kzM65aWXgxo1y8q0",
          name: "Runnerguy2489",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/Runnerguy.png",
          twitchChannel: "https://www.twitch.tv/runnerguy2489",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H15M32.953961S",
        finishTimeSeconds: 4532,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R2",
    scheduledTime: new Date("2023-09-20T00:01:04.330Z"),
    racetimeId: "oot/clean-shadow-6361",
  },
  {
    id: "95cadade-17d3-4309-b126-1cbf1e5a84a8",
    entrants: [
      {
        user: {
          id: "ZVa0eMonnbol9pyJ",
          name: "adef",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/adef.png",
          twitchChannel: "https://www.twitch.tv/adef",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H21M47.100387S",
        finishTimeSeconds: 4907,
        racetimePlace: 1,
      },
      {
        user: {
          id: "rZyM4orRvRoqDJX0",
          name: "jenslang",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/e0aaf3c8688abd0e58bed9d9f63de4ad.png",
          twitchChannel: "https://www.twitch.tv/jenslang",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H7M11.763066S",
        finishTimeSeconds: 4031,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Pre-Eights",
    scheduledTime: new Date("2023-09-05T00:15:43.868Z"),
    racetimeId: "oot/lawful-guay-3216",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
  },
  {
    id: "54768a6e-2a73-49df-bbac-ec7a34946582",
    entrants: [
      {
        user: {
          id: "wdm1LPWjAoEnVx6k",
          name: "dotzo",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/dotzo_-_black_aO9qZEm.png",
          twitchChannel: "https://www.twitch.tv/dotzo",
        },
        state: EntrantState.DidNotFinish,
      },
      {
        user: {
          id: "OR6ym83mnjoPd1Xr",
          name: "Amateseru",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/amateseru",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H14M49.0186S",
        finishTimeSeconds: 4489,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Pre-Eights",
    scheduledTime: new Date("2023-09-06T20:00:10.472Z"),
    racetimeId: "oot/pogtastic-guay-8944",
  },
  {
    id: "b7a70562-3948-45a8-9739-a46cf43966d1",
    entrants: [
      {
        user: {
          id: "kzM65aWX6b31y8q0",
          name: "Nalle",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/gator2gif.gif",
          twitchChannel: "https://www.twitch.tv/nallesounds",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H28M11.90114S",
        finishTimeSeconds: 5291,
        racetimePlace: 1,
      },
      {
        user: {
          id: "Ek8wpok9GkB5KQyV",
          name: "neefe",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/ivysquare.png",
          twitchChannel: "https://www.twitch.tv/neefe",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H24M54.249333S",
        finishTimeSeconds: 5094,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Pre-Eights",
    scheduledTime: new Date("2023-09-06T22:00:33.935Z"),
    racetimeId: "oot/splendid-boomerang-1100",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "aGklxjWzQvoLPdye",
      name: "noface099",
      roles: [Role.Admin, Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/face_saulte_56.png",
      twitchChannel: "https://www.twitch.tv/noface099",
    },
  },
  {
    id: "1a573298-52ab-4eec-b5d6-bafc7cb659eb",
    entrants: [
      {
        user: {
          id: "OR6ym83myb3Pd1Xr",
          name: "HappyHikerPhilippeTitou",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/Sans_titre-removebg-preview_4e6yOAB.png",
          twitchChannel: "https://www.twitch.tv/p_titou",
        },
        state: EntrantState.DidNotFinish,
      },
      {
        user: {
          id: "wNZ1KRBOV8W4qAyj",
          name: "tob3000",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/tob3000",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H8M55.694096S",
        finishTimeSeconds: 4135,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R5",
    scheduledTime: new Date("2023-10-17T20:02:56.039Z"),
    racetimeId: "oot/virtual-dekutree-2482",
  },
  {
    id: "4ff39549-2671-4966-8c3f-af15af15b5af",
    entrants: [
      {
        user: {
          id: "OR6ym83mnjoPd1Xr",
          name: "Amateseru",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/amateseru",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H9M3.804149S",
        finishTimeSeconds: 4143,
        racetimePlace: 2,
      },
      {
        user: {
          id: "LxldAMBlnboaOP57",
          name: "LiterallyLake",
          roles: [Role.Entrant, Role.Restreamer],
          twitchChannel: "https://www.twitch.tv/literallylake",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H13M43.347667S",
        finishTimeSeconds: 4423,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R3",
    scheduledTime: new Date("2023-10-01T18:33:28.349Z"),
    racetimeId: "oot/splendid-skullmask-3992",
  },
  {
    id: "fa6964bd-1b8d-45d1-b2c9-f3b98bc29faf",
    entrants: [
      {
        user: {
          id: "NqO2YoLDL8o9QEya",
          name: "Jake Wright",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/flat750x075f-pad750x1000f8f8f8.png",
          twitchChannel: "https://www.twitch.tv/jakewrlght",
        },
        state: EntrantState.DidNotFinish,
      },
      {
        user: {
          id: "Aa5veoGybABMVr6Z",
          name: "triforce3250",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/ZeldaRacetimeThumbnail.jpg",
          twitchChannel: "https://www.twitch.tv/triforce3250",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H16M57.479392S",
        finishTimeSeconds: 4617,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Pre-Eights",
    scheduledTime: new Date("2023-09-09T18:03:17.993Z"),
    racetimeId: "oot/invincible-keese-9891",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/aXMO4qdvf4I",
  },
  {
    id: "3ab7e881-f0c9-44a5-91f9-805559958080",
    entrants: [
      {
        user: {
          id: "wNZ1KRBOV8W4qAyj",
          name: "tob3000",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/tob3000",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H5M42.895208S",
        finishTimeSeconds: 3942,
        racetimePlace: 1,
      },
      {
        user: {
          id: "Qbq4dBpJnrovlrG0",
          name: "skepticole",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/skepticole",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H25M57.410808S",
        finishTimeSeconds: 5157,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Pre-Eights",
    scheduledTime: new Date("2023-09-09T22:15:50.640Z"),
    racetimeId: "oot/smart-dungeon-6343",
  },
  {
    id: "0fd3103d-7a1e-4729-bbc7-61b295a6ce61",
    entrants: [
      {
        user: {
          id: "jQbq4dBpey3vlrG0",
          name: "MooseOoT",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/5819539783680",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H15M22.239234S",
        finishTimeSeconds: 4522,
        racetimePlace: 2,
      },
      {
        user: {
          id: "rZyM4orRvRoqDJX0",
          name: "jenslang",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/e0aaf3c8688abd0e58bed9d9f63de4ad.png",
          twitchChannel: "https://www.twitch.tv/jenslang",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H15M35.444375S",
        finishTimeSeconds: 4535,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Eighths",
    scheduledTime: new Date("2023-09-16T00:07:08.620Z"),
    racetimeId: "oot/critical-hammer-6709",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/rVf4Oz5N2F4",
  },
  {
    id: "cf0f13f6-fe64-47c1-91f7-84a465e53d8d",
    entrants: [
      {
        user: {
          id: "DMLq1oZ98e3OeQG8",
          name: "Eggmeister",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/egg2_2.png",
          twitchChannel: "https://www.twitch.tv/eggmeister321",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H19M56.678232S",
        finishTimeSeconds: 4796,
        racetimePlace: 2,
      },
      {
        user: {
          id: "Va0eMongz6Wl9pyJ",
          name: "2DollarGargoyle",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/poe_triforce_better.png",
          twitchChannel: "https://www.twitch.tv/2dollargargoyle",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H16M26.826134S",
        finishTimeSeconds: 4586,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Eighths",
    scheduledTime: new Date("2023-09-16T18:00:57.978Z"),
    racetimeId: "oot/quick-spirit-7639",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/HQ401hRUKNM",
  },
  {
    id: "cf23e6ec-7056-492e-ad0f-f81d36dd6611",
    entrants: [
      {
        user: {
          id: "Qbq4dBpJnrovlrG0",
          name: "skepticole",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/skepticole",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H48M37.368383S",
        finishTimeSeconds: 6517,
        racetimePlace: 2,
      },
      {
        user: {
          id: "7lYZa5B5eZB2Vwv9",
          name: "MutantAura",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/5454518169c837a73da4f74202a5960a.webp",
          twitchChannel: "https://www.twitch.tv/mutantaura",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H13M38.741282S",
        finishTimeSeconds: 4418,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R1",
    scheduledTime: new Date("2023-09-21T16:59:38.896Z"),
    racetimeId: "oot/snug-redead-8866",
  },
  {
    id: "0cd655ef-d70d-4ccc-80ff-0bb12b6e835d",
    entrants: [
      {
        user: {
          id: "wNZ1KRBOV8W4qAyj",
          name: "tob3000",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/tob3000",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H3M28.104543S",
        finishTimeSeconds: 3808,
        racetimePlace: 2,
      },
      {
        user: {
          id: "5rNGD3DKVaB9blOy",
          name: "FantaTanked",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/jimmyW.png",
          twitchChannel: "https://www.twitch.tv/fantatanked",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H8M3.048704S",
        finishTimeSeconds: 4083,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Quarters",
    scheduledTime: new Date("2023-10-01T13:05:24.681Z"),
    racetimeId: "oot/agreeable-triforce-5093",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
    vodUrl: "https://youtu.be/SJhpZzJs5gg",
  },
  {
    id: "756fffdc-effc-4531-8ae1-9590b0d098b9",
    entrants: [
      {
        user: {
          id: "52QE8oNlGXBlywqX",
          name: "Grego",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/purplepixel_DXDp79m.png",
          twitchChannel: "https://www.twitch.tv/07151129",
        },
        state: EntrantState.DidNotFinish,
      },
      {
        user: {
          id: "7lYZa5B5eZB2Vwv9",
          name: "MutantAura",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/5454518169c837a73da4f74202a5960a.webp",
          twitchChannel: "https://www.twitch.tv/mutantaura",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H18M59.006405S",
        finishTimeSeconds: 4739,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Pre-Eights",
    scheduledTime: new Date("2023-09-09T22:33:34.760Z"),
    racetimeId: "oot/sunken-gibdo-6513",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "aGklxjWzQvoLPdye",
      name: "noface099",
      roles: [Role.Admin, Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/face_saulte_56.png",
      twitchChannel: "https://www.twitch.tv/noface099",
    },
    vodUrl: "https://youtu.be/pC8YHUFMaJQ",
  },
  {
    id: "42ae41e9-cbdd-48a9-88d2-bd598ef22bad",
    entrants: [
      {
        user: {
          id: "OR6ym83myb3Pd1Xr",
          name: "HappyHikerPhilippeTitou",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/Sans_titre-removebg-preview_4e6yOAB.png",
          twitchChannel: "https://www.twitch.tv/p_titou",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H18M35.80208S",
        finishTimeSeconds: 4715,
        racetimePlace: 2,
      },
      {
        user: {
          id: "yMewn83V613405Jv",
          name: "TKC",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/qYgfSWJ__400x400.jpg",
          twitchChannel: "https://www.twitch.tv/tkc014",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H15M32.279611S",
        finishTimeSeconds: 4532,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Quarters",
    scheduledTime: new Date("2023-09-22T13:45:59.041Z"),
    racetimeId: "oot/odd-freezard-3152",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
    vodUrl: "https://youtu.be/RZ9pu1JQPEg",
  },
  {
    id: "1d7fb3fc-a564-4e63-84ea-42ebce9de169",
    entrants: [
      {
        user: {
          id: "LxldAMBlnboaOP57",
          name: "LiterallyLake",
          roles: [Role.Entrant, Role.Restreamer],
          twitchChannel: "https://www.twitch.tv/literallylake",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H27M51.531542S",
        finishTimeSeconds: 5271,
        racetimePlace: 1,
      },
      {
        user: {
          id: "5rNGD3DKVaB9blOy",
          name: "FantaTanked",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/jimmyW.png",
          twitchChannel: "https://www.twitch.tv/fantatanked",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H7M40.955834S",
        finishTimeSeconds: 4060,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Eighths",
    scheduledTime: new Date("2023-09-17T16:03:11.023Z"),
    racetimeId: "oot/mysterious-eyeballfrog-1604",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/NGuY4bEJwZs",
  },
  {
    id: "e82a56b0-5139-42bd-9a24-fc1cb8603b40",
    entrants: [
      {
        user: {
          id: "OR6ym83mnjoPd1Xr",
          name: "Amateseru",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/amateseru",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H15M26.661296S",
        finishTimeSeconds: 4526,
        racetimePlace: 1,
      },
      {
        user: {
          id: "Aa5veoGybABMVr6Z",
          name: "triforce3250",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/ZeldaRacetimeThumbnail.jpg",
          twitchChannel: "https://www.twitch.tv/triforce3250",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H13M36.851451S",
        finishTimeSeconds: 4416,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Quarters",
    scheduledTime: new Date("2023-09-24T17:04:00.275Z"),
    racetimeId: "oot/proud-kokiri-7055",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/0BsSU-C1fxo",
  },
  {
    id: "8c8be5ec-6331-425a-b598-81cbc5b27452",
    entrants: [
      {
        user: {
          id: "ZbpNAaBvn5BJkg04",
          name: "Exodus",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/bottleblack_skXfQZO.png",
          twitchChannel: "https://www.twitch.tv/exodus122",
        },
        state: EntrantState.DidNotFinish,
      },
      {
        user: {
          id: "5rNGD3DKVaB9blOy",
          name: "FantaTanked",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/jimmyW.png",
          twitchChannel: "https://www.twitch.tv/fantatanked",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H6M5.320377S",
        finishTimeSeconds: 3965,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R3",
    scheduledTime: new Date("2023-10-07T18:02:17.304Z"),
    racetimeId: "oot/crafty-medallion-0453",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
    vodUrl: "https://youtu.be/oM51z7VgDCI",
  },
  {
    id: "063e7aa5-bd6c-47cf-a7f8-6d0d8c498dce",
    entrants: [
      {
        user: {
          id: "wNZ1KRBOV8W4qAyj",
          name: "tob3000",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/tob3000",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H4M39.300874S",
        finishTimeSeconds: 3879,
        racetimePlace: 2,
      },
      {
        user: {
          id: "Va0eMongz6Wl9pyJ",
          name: "2DollarGargoyle",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/poe_triforce_better.png",
          twitchChannel: "https://www.twitch.tv/2dollargargoyle",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H10M41.064974S",
        finishTimeSeconds: 4241,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R7",
    scheduledTime: new Date("2023-11-03T21:05:23.922Z"),
    racetimeId: "oot/foolish-ruto-0785",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
    vodUrl: "https://youtu.be/AjcL0TPx4Oo",
  },
  {
    id: "8e126e38-a0f5-4bb2-9755-0b56c0e11999",
    entrants: [
      {
        user: {
          id: "wNZ1KRBOV8W4qAyj",
          name: "tob3000",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/tob3000",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H10M18.961487S",
        finishTimeSeconds: 4218,
        racetimePlace: 2,
      },
      {
        user: {
          id: "jQbq4dBp7yWvlrG0",
          name: "Link11",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/Link-1.png",
          twitchChannel: "https://www.twitch.tv/link__11",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H16M26.378092S",
        finishTimeSeconds: 4586,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Eighths",
    scheduledTime: new Date("2023-09-14T22:36:34.443Z"),
    racetimeId: "oot/fancy-bracelet-2657",
  },
  {
    id: "4159ae36-e6f6-4c17-86a0-edc0e9797266",
    entrants: [
      {
        user: {
          id: "yMewn83V613405Jv",
          name: "TKC",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/qYgfSWJ__400x400.jpg",
          twitchChannel: "https://www.twitch.tv/tkc014",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H9M40.169879S",
        finishTimeSeconds: 4180,
        racetimePlace: 2,
      },
      {
        user: {
          id: "Va0eMongz6Wl9pyJ",
          name: "2DollarGargoyle",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/poe_triforce_better.png",
          twitchChannel: "https://www.twitch.tv/2dollargargoyle",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H12M46.377412S",
        finishTimeSeconds: 4366,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Finals",
    scheduledTime: new Date("2023-10-21T14:01:23.371Z"),
    racetimeId: "oot/crazy-bulletbag-5132",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
    vodUrl: "https://youtu.be/8xZtd2Dz1XU",
  },
  {
    id: "e07c9e04-d795-4856-87cc-01eb709a7300",
    entrants: [
      {
        user: {
          id: "vrZyM4orqE3qDJX0",
          name: "Woli",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/5e9d035f8ad1c385bf6db77cb5761628.jpg",
          twitchChannel: "https://www.twitch.tv/wolisecondary",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H7M32.246839S",
        finishTimeSeconds: 4052,
        racetimePlace: 2,
      },
      {
        user: {
          id: "LxldAMBlnboaOP57",
          name: "LiterallyLake",
          roles: [Role.Entrant, Role.Restreamer],
          twitchChannel: "https://www.twitch.tv/literallylake",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H7M10.854402S",
        finishTimeSeconds: 4030,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Pre-Eights",
    scheduledTime: new Date("2023-09-10T18:01:43.522Z"),
    racetimeId: "oot/snug-likelike-4276",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/RNKVBeE8LoU",
  },
  {
    id: "68867de1-2dfa-40de-a56a-eab034a19703",
    entrants: [
      {
        user: {
          id: "OR6ym83myb3Pd1Xr",
          name: "HappyHikerPhilippeTitou",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/Sans_titre-removebg-preview_4e6yOAB.png",
          twitchChannel: "https://www.twitch.tv/p_titou",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H16M6.421215S",
        finishTimeSeconds: 4566,
        racetimePlace: 2,
      },
      {
        user: {
          id: "7lYZa5B5eZB2Vwv9",
          name: "MutantAura",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/5454518169c837a73da4f74202a5960a.webp",
          twitchChannel: "https://www.twitch.tv/mutantaura",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H33M48.830761S",
        finishTimeSeconds: 5628,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Eighths",
    scheduledTime: new Date("2023-09-15T15:30:37.912Z"),
    racetimeId: "oot/cute-bombbag-7309",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
  },
  {
    id: "b98b4922-0338-4df6-824f-50f692fdcf42",
    entrants: [
      {
        user: {
          id: "rZyM4orRvRoqDJX0",
          name: "jenslang",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/e0aaf3c8688abd0e58bed9d9f63de4ad.png",
          twitchChannel: "https://www.twitch.tv/jenslang",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H13M45.705632S",
        finishTimeSeconds: 4425,
        racetimePlace: 1,
      },
      {
        user: {
          id: "DMLq1oZ98e3OeQG8",
          name: "Eggmeister",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/egg2_2.png",
          twitchChannel: "https://www.twitch.tv/eggmeister321",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H12M22.54848S",
        finishTimeSeconds: 4342,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R2",
    scheduledTime: new Date("2023-09-24T19:01:53.633Z"),
    racetimeId: "oot/calm-medallion-1574",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/MPy3cyOLA14",
  },
  {
    id: "c844fb5c-e660-4bec-b0a5-edef93367076",
    entrants: [
      {
        user: {
          id: "ZbpNAaBvn5BJkg04",
          name: "Exodus",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/bottleblack_skXfQZO.png",
          twitchChannel: "https://www.twitch.tv/exodus122",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H16M27.412233S",
        finishTimeSeconds: 4587,
        racetimePlace: 1,
      },
      {
        user: {
          id: "OR6ym83mnjoPd1Xr",
          name: "Amateseru",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/amateseru",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H14M36.933504S",
        finishTimeSeconds: 4476,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Eighths",
    scheduledTime: new Date("2023-09-14T17:03:03.235Z"),
    racetimeId: "oot/adequate-skullmask-0381",
  },
  {
    id: "cec6f20e-c8a8-49a8-90e3-410321ca4037",
    entrants: [
      {
        user: {
          id: "jQbq4dBpey3vlrG0",
          name: "MooseOoT",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/5819539783680",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H19M32.286103S",
        finishTimeSeconds: 4772,
        racetimePlace: 2,
      },
      {
        user: {
          id: "Va0eMongz6Wl9pyJ",
          name: "2DollarGargoyle",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/poe_triforce_better.png",
          twitchChannel: "https://www.twitch.tv/2dollargargoyle",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H12M33.813502S",
        finishTimeSeconds: 4353,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Quarters",
    scheduledTime: new Date("2023-09-27T20:02:24.979Z"),
    racetimeId: "oot/calm-volvagia-7149",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
    vodUrl: "https://youtu.be/oNa1uBHkVVg",
  },
  {
    id: "9ab8d973-57a3-40c2-af62-2000b8e5ff67",
    entrants: [
      {
        user: {
          id: "vrZyM4orqE3qDJX0",
          name: "Woli",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/5e9d035f8ad1c385bf6db77cb5761628.jpg",
          twitchChannel: "https://www.twitch.tv/wolisecondary",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H13M36.979892S",
        finishTimeSeconds: 4416,
        racetimePlace: 2,
      },
      {
        user: {
          id: "Ek8wpok9GkB5KQyV",
          name: "neefe",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/ivysquare.png",
          twitchChannel: "https://www.twitch.tv/neefe",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H18M33.283815S",
        finishTimeSeconds: 4713,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R1",
    scheduledTime: new Date("2023-09-16T18:02:05.766Z"),
    racetimeId: "oot/epic-forest-8829",
  },
  {
    id: "1c958e16-2f3b-4c16-9835-2916ab05f5ad",
    entrants: [
      {
        user: {
          id: "Aa5veoGybABMVr6Z",
          name: "triforce3250",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/ZeldaRacetimeThumbnail.jpg",
          twitchChannel: "https://www.twitch.tv/triforce3250",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H15M59.379274S",
        finishTimeSeconds: 4559,
        racetimePlace: 2,
      },
      {
        user: {
          id: "kzM65aWXgxo1y8q0",
          name: "Runnerguy2489",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/Runnerguy.png",
          twitchChannel: "https://www.twitch.tv/runnerguy2489",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H19M4.239892S",
        finishTimeSeconds: 4744,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Eighths",
    scheduledTime: new Date("2023-09-15T00:03:30.508Z"),
    racetimeId: "oot/powerful-water-3134",
  },
  {
    id: "fff26406-5d9a-4726-b0cf-6852b1702552",
    entrants: [
      {
        user: {
          id: "kzM65aWXgxo1y8q0",
          name: "Runnerguy2489",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/Runnerguy.png",
          twitchChannel: "https://www.twitch.tv/runnerguy2489",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H17M14.305827S",
        finishTimeSeconds: 4634,
        racetimePlace: 1,
      },
      {
        user: {
          id: "R8QGZrB2k03Ngk4V",
          name: "Challensois_",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/Malon_copy.png",
          twitchChannel: "https://www.twitch.tv/challensois_",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H19M6.287532S",
        finishTimeSeconds: 4746,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R1",
    scheduledTime: new Date("2023-09-17T17:39:07.078Z"),
    racetimeId: "oot/hyper-bugs-1779",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/GAc-qH06ijk",
  },
  {
    id: "3a5bfa7d-81f5-45e1-b9a2-20fc842e91c9",
    entrants: [
      {
        user: {
          id: "yMewn83V613405Jv",
          name: "TKC",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/qYgfSWJ__400x400.jpg",
          twitchChannel: "https://www.twitch.tv/tkc014",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H7M7.156977S",
        finishTimeSeconds: 4027,
        racetimePlace: 1,
      },
      {
        user: {
          id: "Ek8wpok9GkB5KQyV",
          name: "neefe",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/ivysquare.png",
          twitchChannel: "https://www.twitch.tv/neefe",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H20M52.667349S",
        finishTimeSeconds: 4852,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Eighths",
    scheduledTime: new Date("2023-09-16T01:02:47.206Z"),
    racetimeId: "oot/salty-quiver-5182",
  },
  {
    id: "4e8253f1-aa10-4844-9711-85e529b7a5d7",
    entrants: [
      {
        user: {
          id: "vrZyM4orqE3qDJX0",
          name: "Woli",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/5e9d035f8ad1c385bf6db77cb5761628.jpg",
          twitchChannel: "https://www.twitch.tv/wolisecondary",
        },
        state: EntrantState.DidNotFinish,
      },
      {
        user: {
          id: "7lYZa5B5eZB2Vwv9",
          name: "MutantAura",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/5454518169c837a73da4f74202a5960a.webp",
          twitchChannel: "https://www.twitch.tv/mutantaura",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H15M54.612035S",
        finishTimeSeconds: 4554,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R2",
    scheduledTime: new Date("2023-09-22T15:16:18.507Z"),
    racetimeId: "oot/obedient-wolfos-8246",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
    vodUrl: "https://youtu.be/LDlwUspbb3M",
  },
  {
    id: "92676b7e-0e36-48e7-b62b-1dd10f15bd14",
    entrants: [
      {
        user: {
          id: "jQbq4dBpey3vlrG0",
          name: "MooseOoT",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/5819539783680",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H22M54S",
        finishTimeSeconds: 4974,
        racetimePlace: 1,
      },
      {
        user: {
          id: "7lYZa5B5eZB2Vwv9",
          name: "MutantAura",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/5454518169c837a73da4f74202a5960a.webp",
          twitchChannel: "https://www.twitch.tv/mutantaura",
        },
        state: EntrantState.DidNotFinish,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R3",
    scheduledTime: new Date("2023-09-30T16:01:10.662Z"),
    racetimeId: "oot/gnarly-twinrova-0203",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
    vodUrl: "https://youtu.be/R5l_0GTbmis",
  },
  {
    id: "a8e23c57-232b-414d-8256-75e2fcb45ab2",
    entrants: [
      {
        user: {
          id: "wdm1LPWjAoEnVx6k",
          name: "dotzo",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/dotzo_-_black_aO9qZEm.png",
          twitchChannel: "https://www.twitch.tv/dotzo",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H17M28.977064S",
        finishTimeSeconds: 4648,
        racetimePlace: 1,
      },
      {
        user: {
          id: "rZyM4orRvRoqDJX0",
          name: "jenslang",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/e0aaf3c8688abd0e58bed9d9f63de4ad.png",
          twitchChannel: "https://www.twitch.tv/jenslang",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H14M34.065761S",
        finishTimeSeconds: 4474,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R1",
    scheduledTime: new Date("2023-09-23T22:03:07.921Z"),
    racetimeId: "oot/saucy-rauru-0688",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/bVBWJadNCZU",
  },
  {
    id: "cdaad0ec-bf9e-4253-90eb-9d5cd7151a89",
    entrants: [
      {
        user: {
          id: "OR6ym83myb3Pd1Xr",
          name: "HappyHikerPhilippeTitou",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/Sans_titre-removebg-preview_4e6yOAB.png",
          twitchChannel: "https://www.twitch.tv/p_titou",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H11M51.101655S",
        finishTimeSeconds: 4311,
        racetimePlace: 2,
      },
      {
        user: {
          id: "OR6ym83mnjoPd1Xr",
          name: "Amateseru",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/amateseru",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H19M2.565593S",
        finishTimeSeconds: 4742,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R4",
    scheduledTime: new Date("2023-10-05T18:00:26.957Z"),
    racetimeId: "oot/amazing-kakariko-8256",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
    vodUrl: "https://youtu.be/oP52CzyCdhM",
  },
  {
    id: "0b7271b7-39f9-46c2-b24d-fbf0fefea6b9",
    entrants: [
      {
        user: {
          id: "wNZ1KRBOV8W4qAyj",
          name: "tob3000",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/tob3000",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H6M7.64694S",
        finishTimeSeconds: 3967,
        racetimePlace: 2,
      },
      {
        user: {
          id: "5rNGD3DKVaB9blOy",
          name: "FantaTanked",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/jimmyW.png",
          twitchChannel: "https://www.twitch.tv/fantatanked",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H10M4.768278S",
        finishTimeSeconds: 4204,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R6",
    scheduledTime: new Date("2023-10-26T18:35:32.768Z"),
    racetimeId: "oot/dizzy-hookshot-7204",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
  },
  {
    id: "9fbd85a7-b500-4940-822a-d74e8bab119e",
    entrants: [
      {
        user: {
          id: "XGzr7pBMyqBkqgyE",
          name: "TomPouce",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/yeeeeeh.jpg",
          twitchChannel: "https://www.twitch.tv/originaltompouce",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H31M53.413084S",
        finishTimeSeconds: 5513,
        racetimePlace: 2,
      },
      {
        user: {
          id: "OR6ym83myb3Pd1Xr",
          name: "HappyHikerPhilippeTitou",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/Sans_titre-removebg-preview_4e6yOAB.png",
          twitchChannel: "https://www.twitch.tv/p_titou",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H12M59.28285S",
        finishTimeSeconds: 4379,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-13T18:24:19.261Z"),
    racetimeId: "oot/dynamax-colossus-5738",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "d17DexWEKg3ak64R",
      name: "xwillmarktheplace",
      roles: [Role.Admin],
      avatar: "https://racetime.gg/media/Racetime.png",
      twitchChannel: "https://www.twitch.tv/xwillmarktheplace",
    },
    vodUrl: "https://youtu.be/C92mTE30Gmo",
  },
  {
    id: "428bf113-d134-4bb0-a140-1da0ddd332a8",
    entrants: [
      {
        user: {
          id: "d17DexWEkR3ak64R",
          name: "gsk8",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/teteaucarr%C3%A93112.png",
          twitchChannel: "https://www.twitch.tv/gsk8",
        },
        state: EntrantState.DidNotFinish,
      },
      {
        user: {
          id: "aGklxjWzQvoLPdye",
          name: "noface099",
          roles: [Role.Admin, Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/face_saulte_56.png",
          twitchChannel: "https://www.twitch.tv/noface099",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H28M42.977019S",
        finishTimeSeconds: 5322,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 1",
    scheduledTime: new Date("2023-08-19T19:47:32.784Z"),
    racetimeId: "oot/gorgeous-ocarina-6705",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
  },
  {
    id: "52d0d375-a885-4589-ae5a-32b16666f9fc",
    entrants: [
      {
        user: {
          id: "ZbpNAaBvn5BJkg04",
          name: "Exodus",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/bottleblack_skXfQZO.png",
          twitchChannel: "https://www.twitch.tv/exodus122",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H5M21.325137S",
        finishTimeSeconds: 3921,
        racetimePlace: 1,
      },
      {
        user: {
          id: "ZVa0eMonnbol9pyJ",
          name: "adef",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/adef.png",
          twitchChannel: "https://www.twitch.tv/adef",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H22M19.894979S",
        finishTimeSeconds: 4939,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R1",
    scheduledTime: new Date("2023-09-18T20:55:39.937Z"),
    racetimeId: "oot/wonderful-knuckle-2327",
  },
  {
    id: "d6549bcf-2876-4e60-93e6-a40cebb05f76",
    entrants: [
      {
        user: {
          id: "52QE8oNlGXBlywqX",
          name: "Grego",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/purplepixel_DXDp79m.png",
          twitchChannel: "https://www.twitch.tv/07151129",
        },
        state: EntrantState.DidNotFinish,
      },
      {
        user: {
          id: "jQbq4dBp7yWvlrG0",
          name: "Link11",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/Link-1.png",
          twitchChannel: "https://www.twitch.tv/link__11",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H18M19.083659S",
        finishTimeSeconds: 4699,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R1",
    scheduledTime: new Date("2023-09-19T23:19:36.672Z"),
    racetimeId: "oot/daring-poe-6450",
  },
  {
    id: "133f3f75-d686-4278-ba7e-3b9e442bd8ff",
    entrants: [
      {
        user: {
          id: "yMewn83V613405Jv",
          name: "TKC",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/qYgfSWJ__400x400.jpg",
          twitchChannel: "https://www.twitch.tv/tkc014",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H6M43.669797S",
        finishTimeSeconds: 4003,
        racetimePlace: 1,
      },
      {
        user: {
          id: "Aa5veoGybABMVr6Z",
          name: "triforce3250",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/ZeldaRacetimeThumbnail.jpg",
          twitchChannel: "https://www.twitch.tv/triforce3250",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H10M38.69467S",
        finishTimeSeconds: 4238,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Semis",
    scheduledTime: new Date("2023-10-15T00:03:24.691Z"),
    racetimeId: "oot/banzai-dungeonmap-7793",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/T3IfY0oWMGY",
  },
  {
    id: "d86ed642-4f6a-4b44-885a-4078eac90867",
    entrants: [
      {
        user: {
          id: "wNZ1KRBOV8W4qAyj",
          name: "tob3000",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/tob3000",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H7M56.767704S",
        finishTimeSeconds: 4076,
        racetimePlace: 1,
      },
      {
        user: {
          id: "yMewn83V613405Jv",
          name: "TKC",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/qYgfSWJ__400x400.jpg",
          twitchChannel: "https://www.twitch.tv/tkc014",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H12M22.702146S",
        finishTimeSeconds: 4342,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Grand Finals",
    scheduledTime: new Date("2023-11-06T14:05:29.237Z"),
    racetimeId: "oot/funky-sword-2495",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
    vodUrl: "https://youtu.be/rXKT26cLTKs",
  },
  {
    id: "82d9e8e3-5bb2-4a57-a440-78576eac484d",
    entrants: [
      {
        user: {
          id: "wNZ1KRBOV8W4qAyj",
          name: "tob3000",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/tob3000",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H25M44.263408S",
        finishTimeSeconds: 5144,
        racetimePlace: 2,
      },
      {
        user: {
          id: "Va0eMongz6Wl9pyJ",
          name: "2DollarGargoyle",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/poe_triforce_better.png",
          twitchChannel: "https://www.twitch.tv/2dollargargoyle",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H15M46.49927S",
        finishTimeSeconds: 4546,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Semis",
    scheduledTime: new Date("2023-10-14T22:03:35.151Z"),
    racetimeId: "oot/comic-dekutree-0418",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/ix65L71qa10",
  },
  {
    id: "4309086b-ae51-4518-b5c9-918c38e678ce",
    entrants: [
      {
        user: {
          id: "jQbq4dBpey3vlrG0",
          name: "MooseOoT",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/5819539783680",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H11M29.819525S",
        finishTimeSeconds: 4289,
        racetimePlace: 2,
      },
      {
        user: {
          id: "5rNGD3DKVaB9blOy",
          name: "FantaTanked",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/jimmyW.png",
          twitchChannel: "https://www.twitch.tv/fantatanked",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H10M47.285756S",
        finishTimeSeconds: 4247,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R4",
    scheduledTime: new Date("2023-10-14T16:00:55.078Z"),
    racetimeId: "oot/adequate-bosskey-8970",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
  },
  {
    id: "30abe176-be8c-426c-9c07-664e21e804c6",
    entrants: [
      {
        user: {
          id: "OR6ym83myb3Pd1Xr",
          name: "HappyHikerPhilippeTitou",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/Sans_titre-removebg-preview_4e6yOAB.png",
          twitchChannel: "https://www.twitch.tv/p_titou",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H12M42.288862S",
        finishTimeSeconds: 4362,
        racetimePlace: 1,
      },
      {
        user: {
          id: "DMLq1oZ98e3OeQG8",
          name: "Eggmeister",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/egg2_2.png",
          twitchChannel: "https://www.twitch.tv/eggmeister321",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H14M49.592132S",
        finishTimeSeconds: 4489,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R3",
    scheduledTime: new Date("2023-10-01T15:00:47.716Z"),
    racetimeId: "oot/speedy-peahat-4798",
  },
  {
    id: "79394dd8-92e7-48b8-8d00-7af2c6bf297d",
    entrants: [
      {
        user: {
          id: "wNZ1KRBOV8W4qAyj",
          name: "tob3000",
          roles: [Role.Entrant],
          twitchChannel: "https://www.twitch.tv/tob3000",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H4M25.208862S",
        finishTimeSeconds: 3865,
        racetimePlace: 1,
      },
      {
        user: {
          id: "yMewn83V613405Jv",
          name: "TKC",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/qYgfSWJ__400x400.jpg",
          twitchChannel: "https://www.twitch.tv/tkc014",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H26M30.083919S",
        finishTimeSeconds: 5190,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Grand Finals",
    scheduledTime: new Date("2023-11-12T00:03:43.748Z"),
    racetimeId: "oot/saucy-bolero-1197",
    restreamChannel: "https://twitch.tv/OoTv",
    restreamUser: {
      id: "kzM65aWX6b31y8q0",
      name: "Nalle",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/gator2gif.gif",
      twitchChannel: "https://www.twitch.tv/nallesounds",
    },
    vodUrl: "https://youtu.be/TTkROADBIZQ",
  },
  {
    id: "321b079c-16fc-4d3f-b189-b08866cffd57",
    entrants: [
      {
        user: {
          id: "LxldAMBlnboaOP57",
          name: "LiterallyLake",
          roles: [Role.Entrant, Role.Restreamer],
          twitchChannel: "https://www.twitch.tv/literallylake",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H13M39.136245S",
        finishTimeSeconds: 4419,
        racetimePlace: 1,
      },
      {
        user: {
          id: "jQbq4dBp7yWvlrG0",
          name: "Link11",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/Link-1.png",
          twitchChannel: "https://www.twitch.tv/link__11",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H19M8.465501S",
        finishTimeSeconds: 4748,
        racetimePlace: 2,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R2",
    scheduledTime: new Date("2023-10-01T17:04:52.392Z"),
    racetimeId: "oot/funky-ingo-9571",
  },
  {
    id: "a7ddbc75-7d7f-4d9c-a38c-49b7d0c3e963",
    entrants: [
      {
        user: {
          id: "kzM65aWXgxo1y8q0",
          name: "Runnerguy2489",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/Runnerguy.png",
          twitchChannel: "https://www.twitch.tv/runnerguy2489",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H21M24.628435S",
        finishTimeSeconds: 4884,
        racetimePlace: 1,
      },
      {
        user: {
          id: "aGklxjWzQvoLPdye",
          name: "noface099",
          roles: [Role.Admin, Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/face_saulte_56.png",
          twitchChannel: "https://www.twitch.tv/noface099",
        },
        state: EntrantState.DidNotFinish,
      },
    ],
    state: MatchState.Finished,
    round: "Round 2",
    scheduledTime: new Date("2023-08-25T23:01:56.152Z"),
    racetimeId: "oot/mega-shadow-4378",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/S_btD2ISwYw",
  },
  {
    id: "1d14cd91-aefc-4e2e-a3a3-6f6564a17b54",
    entrants: [
      {
        user: {
          id: "LxldAMBlnboaOP57",
          name: "LiterallyLake",
          roles: [Role.Entrant, Role.Restreamer],
          twitchChannel: "https://www.twitch.tv/literallylake",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H18M39.781852S",
        finishTimeSeconds: 4719,
        racetimePlace: 2,
      },
      {
        user: {
          id: "jQbq4dBp7yWvlrG0",
          name: "Link11",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/Link-1.png",
          twitchChannel: "https://www.twitch.tv/link__11",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H16M50.139188S",
        finishTimeSeconds: 4610,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Round 3",
    scheduledTime: new Date("2023-08-30T00:04:07.451Z"),
    racetimeId: "oot/splendid-smallkey-6613",
    restreamChannel: "https://twitch.tv/ootv",
    restreamUser: {
      id: "aGklxjWzQvoLPdye",
      name: "noface099",
      roles: [Role.Admin, Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/face_saulte_56.png",
      twitchChannel: "https://www.twitch.tv/noface099",
    },
    vodUrl: "https://youtu.be/aEVhF869ciY",
  },
  {
    id: "d5a10bbc-67e1-43d9-a83b-66c041a05f92",
    entrants: [
      {
        user: {
          id: "DMLq1oZ98e3OeQG8",
          name: "Eggmeister",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/egg2_2.png",
          twitchChannel: "https://www.twitch.tv/eggmeister321",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H15M0.392464S",
        finishTimeSeconds: 4500,
        racetimePlace: 1,
      },
      {
        user: {
          id: "R8QGZrB2k03Ngk4V",
          name: "Challensois_",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/Malon_copy.png",
          twitchChannel: "https://www.twitch.tv/challensois_",
        },
        state: EntrantState.DidNotFinish,
      },
    ],
    state: MatchState.Finished,
    round: "Pre-Eights",
    scheduledTime: new Date("2023-09-09T16:06:00.806Z"),
    racetimeId: "oot/good-knuckle-7204",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/d4BxgFrc4kQ",
  },
  {
    id: "30d45925-32dd-40a1-9259-74dc93b088b8",
    entrants: [
      {
        user: {
          id: "Aa5veoGybABMVr6Z",
          name: "triforce3250",
          roles: [Role.Entrant],
          avatar: "https://racetime.gg/media/ZeldaRacetimeThumbnail.jpg",
          twitchChannel: "https://www.twitch.tv/triforce3250",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H12M22.105088S",
        finishTimeSeconds: 4342,
        racetimePlace: 2,
      },
      {
        user: {
          id: "5rNGD3DKVaB9blOy",
          name: "FantaTanked",
          roles: [Role.Entrant, Role.Restreamer],
          avatar: "https://racetime.gg/media/jimmyW.png",
          twitchChannel: "https://www.twitch.tv/fantatanked",
        },
        state: EntrantState.Finished,
        finishTime: "PT1H8M9.454152S",
        finishTimeSeconds: 4089,
        racetimePlace: 1,
      },
    ],
    state: MatchState.Finished,
    round: "Losers R5",
    scheduledTime: new Date("2023-10-15T17:04:01.399Z"),
    racetimeId: "oot/banzai-bottle-9355",
    restreamChannel: "https://twitch.tv/OoTV",
    restreamUser: {
      id: "jQbq4dBp7yWvlrG0",
      name: "Link11",
      roles: [Role.Entrant, Role.Restreamer],
      avatar: "https://racetime.gg/media/Link-1.png",
      twitchChannel: "https://www.twitch.tv/link__11",
    },
    vodUrl: "https://youtu.be/VB1p30Pcpmo",
  },
];
